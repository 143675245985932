<template>
    <div>
        <b-card
            title="Connexion Expiré"
            tag="article"
            style="max-width: 20rem; text-align: center; position: relative; margin-top: 10%; margin-left: 30%;"
            class="mb-2"
        >
            <b-card-text>
            Votre connexion est expiré, veuillez cliquer sur le bouton pour vous reconnecter.
            </b-card-text>

            <b-button href="https://www.clicmap.fr/" variant="danger">Reconnexion</b-button>
        </b-card>
    </div>
</template>

<script>
    import Vue from "vue";
    import Vuex from "vuex";
    export default {
        name: "NoLogin",
        data: () => ({

        }),
    };
</script>

