<template>
    <div>
        <b-card
            title="Bientôt disponible"
            tag="article"
            style="max-width: 50rem; text-align: center; position: relative; margin-top: 10%; margin-left: 11%;"
            class="mb-2"
        >
            <b-card-text class="messageComing">
            Cet environnement n'est pas encore fonctionnel. Nous travaillons actuellement dessus.
            </b-card-text>

            <b-button href="https://www.clicmap.fr/" variant="danger">Retour à l'accueil</b-button>
        </b-card>
    </div>
</template>

<script>
    import Vue from "vue";
    import Vuex from "vuex";
    export default {
        name: "ComingSoon",
        data: () => ({

        }),
    };
</script>

