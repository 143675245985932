<template>
  <!-- <div> -->
  <div id="div-parametre">
    <b-card
    class="mb-3"
      v-if="dashboardSelected.shared_by != null"
      border-variant="danger"
      header="Dashboard partagé"
      header-border-variant="danger"
      header-text-variant="danger"
      align="center"
    >
      <b-card-text>
        <div v-if="userConnected.id_membre != dashboardSelected.shared_by">
          Ce dashboard est disponible en consultation uniquement car vous a été
          partagé par
          {{ dashboardSelected.pseudo }}
        </div>
        <div v-else>
          Vous avez partagé ce dableau de bord à tous les utilisateurs de
          l'environnement
        </div>
      </b-card-text></b-card
    >
    <div>
      <label class="settings-label1" for="textarea-small"
        >Nom du tableau de bord :</label
      >
      <b-form-input
        v-model="dashboardSelected.name"
        required
        style="text-align: left"
        class="dashboardNameEdit"
      ></b-form-input>
    </div>

    <div>
      <label class="settings-label2  mt-3" for="textarea-small"
        >Description du tableau de bord :</label
      >
      <b-form-textarea
        v-model="dashboardSelected.description"
        required
        style="text-align: left"
        class="dashboardDescEdit"
        size="lg"
        rows="10"
      ></b-form-textarea>
    </div>

    <div
      style="display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5px;"
    >
      <b-button
        v-if="this.$route.name == 'Settings'"
        @click="saveMyDashboard"
        class="btn-save-dash-toolbar"
        id="popover-save-settings"
      >
        <font-awesome-icon icon="save" />
        <span v-show="!drawerStatus" class="btn-header-text-save">
          Enregistrer les modifications</span
        >
      </b-button>

      <b-popover
        v-if="drawerStatus"
        class="header-popover"
        target="popover-save-settings"
        triggers="hover"
        placement="bottom"
      >
        Enregistrer les modifications
      </b-popover>
      <b-button
        v-if="this.$route.name == 'Settings'"
        @click="returnDashboard"
        class="btn-can-dash-toolbar"
        id="popover-can-settings"
      >
        <font-awesome-icon icon="ban" />
        <span v-show="!drawerStatus" class="btn-header-text-del">
          Annuler les modifications</span
        >
      </b-button>
      <b-popover
        v-if="drawerStatus"
        class="header-popover"
        target="popover-can-settings"
        triggers="hover"
        placement="bottom"
      >
        Annuler les modifications
      </b-popover>
      <b-button
        @click="shareMyDashboard"
        v-if="this.$route.name == 'Settings'"
        class="btn-share-dash-toolbar"
        id="popover-delete-settings"
      >
        <font-awesome-icon icon="share" />
        <span v-show="!drawerStatus" class="btn-header-text-del">
          Partager le tableau de bord à tout l'environnement</span
        >
      </b-button>
      <!-- Boutton supprimer tableau -->
      <b-button
        @click="deleteMyDashboard"
        v-if="this.$route.name == 'Settings'"
        class="btn-del-dash-toolbar"
        id="popover-delete-settings"
      >
        <font-awesome-icon icon="trash" />
        <span v-show="!drawerStatus" class="btn-header-text-del">
          Supprimer le tableau de bord</span
        >
      </b-button>
      <b-modal
        ref="modalSupTab"
        id="modal-center"
        centered
        hide-header
        hide-footer
      >
        <div class="cookiesContent" id="cookiesPopup">
          <button class="close" @click="hideModal">✖</button>
          Voulez-vous vraiment supprimer ce tableau de bord?
          <b-row>
            <b-col
              ><button class="button-sup-modal" @click="deleteMyDashboard()">
                Supprimer
              </button></b-col
            >
            <b-col
              ><button class="button-cancel-modal" @click="hideModal">
                Annuler
              </button></b-col
            >
          </b-row>
        </div>
      </b-modal>
      <b-popover
        v-if="drawerStatus"
        class="header-popover"
        target="popover-delete-settings"
        triggers="hover"
        placement="bottom"
      >
        Supprimer le tableau de bord
      </b-popover>
    </div>
    <label class="mt-3">Lien de partage du tableau de bord</label>
    <div class="input-group mb-3">
      <input
        v-model="shareLink"
        type="text"
        class="form-control shareLink"
        style="height: auto;"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          @click="copyToClipboard()"
          type="button"
        >
          Copier
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
export default {
  data: () => ({
    window: {
      width: 0,
      height: 0,
    },
    shareLink: "",
  }),
  async mounted() {
    window.Event.$emit('before-request');
    await this.getUserConnected();
    await this.setDashboardById(this.$route.params.id);
    this.shareLink = await this.getShareLink(this.$route.params.id);
    window.Event.$emit("after-response");
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
   
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    dashboardSelected: {
      get() {
        return this.dashboard;
      },
      set(value) {
        this.setDashboard(value);
      },
    },
    ...Vuex.mapGetters("Dashboard", ["dashboard"]),
    ...Vuex.mapGetters("Authentification", ["drawerStatus", "userConnected"]),
  },
  methods: {
    copyToClipboard() {
      var copyText = document.querySelector(".shareLink");

      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      console.log('copyText.value', copyText.value)
      navigator.clipboard.writeText(copyText.value);
    },
    ...Vuex.mapActions("Authentification", ["getUserConnected"]),
    showModal() {
      this.$refs["modalSupTab"].show();
    },
    hideModal() {
      this.$refs["modalSupTab"].hide();
    },
    deleteMyDashboard() {
      this.deleteDashboard(this.$route.params.id);
      this.$router.push("/tableaux-de-bord");
    },
    saveMyDashboard() {
      this.saveDashboard(this.dashboardSelected);
      this.$router.push({ name: "Dashboard" });
    },
    shareMyDashboard() {
      this.shareDashboard(this.$route.params.id);
    },
    returnDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    ...Vuex.mapActions("Dashboard", [
      "saveDashboard",
      "deleteDashboard",
      "shareDashboard",
      "getShareLink",
      "setDashboardById",
    ]),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>
