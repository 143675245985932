import router from "../router";
import Vue from "vue";
import axios from "axios"

const Authentification = {
    namespaced: true,
    state: {
        token: window.$cookies.get("token")
            ? "Bearer " + window.$cookies.get("token")
            : "",
        tokenLectureSeule: null,
        status: null,
        drawer: false,
        user: {}
    },
    actions: {
        setUserConnected(context, user) {
            context.commit("GETUSERCONNECTED", user);
        },
        async setTokenLectureSeule(context, tokenLectureSeule) {
            context.commit("SETTOKENLECTURESEULE", tokenLectureSeule);
        },
        setDrawer(context, drawer) {
            context.commit("SETDRAWER", drawer);
        },
        login({commit}, usercredential) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append("login", usercredential.username);
                formData.append("password", usercredential.password);
                Vue.axios.post(window.adresseAPI + "login", formData).then(
                    response => {
                        if (typeof response.data.data !== "undefined") {
                            commit("LOGIN", response.data);
                            resolve(response.data.data);
                        } else {
                            resolve(false)
                        }
                    },
                );
            });
        },
        setEnv({commit}, env) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append("env",env);

                Vue.axios.post(window.adresseAPI + "env", formData).then(
                    response => {
                        if (typeof response.data.data !== "undefined") {
                            commit("LOGIN", response.data);
                            resolve(response.data.data);
                        } else {
                            resolve(false)
                        }
                    },
                );
            });
        },
        getUserConnected(context) {
            return new Promise((resolve) => {
                Vue.axios.get(window.adresseAPI + "user").then(
                    response => {
                        if (typeof response.data.data.id_membre !== "undefined") {
                            context.commit("GETUSERCONNECTED", response.data.data);
                            resolve();
                        } else {
                            router.push({
                                name: 'ComingSoon'
                            })
                            Vue.prototype.$awn.alert(
                                "Echec de la récupération de l'utilisateur : " +
                                response.data.message.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 3000
                                }
                            );
                            resolve();
                        }
                    }
                );
            })
        }
    },
    mutations: {
        LOGIN: (state, data) => {
            state.status = "success";
            let now = new Date();
            window.$cookies.set("token",data.data)
            state.token = "Bearer " + data.data;
            Vue.axios.defaults.headers.common["Authorization"] = state.token;
            state.userConnected = null;
        },
        GETUSERCONNECTED: (state, user) => {
            state.user = user;
        },
        SETDRAWER: (state, drawer) => {
            state.drawer = drawer;
        },
        SETTOKENLECTURESEULE: (state, tokenLectureSeule) => {
            state.tokenLectureSeule = tokenLectureSeule;
        }
    },
    getters: {
        isUserConnected: state => !!state.user.length,
        isAuthenticated: state => !!state.token,
        userConnected: state => state.user,
        drawerStatus: state => state.drawer,
        tokenLectureSeule: state => state.tokenLectureSeule
    }
}
export default Authentification;