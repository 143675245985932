<template>
  <div id="sidebar">
    <div class="container-sidebar">
      <b-modal
        v-if="this.$route.name == 'Dashboard'"
        v-model="modalGraph"
        title="Graphiques"
        hide-header
        hide-footer
        class="ajout-graph"
      >
        <div v-for="value in graphs" :key="value.id">
          <b-button
            @click="addItem(value)"
            variant="light"
            class="bouton-ajout-graph"
          >
            <font-awesome-icon
              :icon="value.icon"
              class="icon-ajout-graph"
              style="width: 100%; height: 80px"
            ></font-awesome-icon>
            <a> {{ value.name }}</a>
          </b-button>
        </div>
      </b-modal>

      <b-modal
        v-if="this.$route.name == 'Dashboard'"
        v-model="modalDashboardName"
        title="Nom du tableau de bord"
        hide-header
        hide-footer
        class="ajout-dashboard"
        size="lg"
      >
        <div class="container-remove">
          <a class="icon-remove-graph">
            <font-awesome-icon @click="closeModal()" icon="times"
          /></a>
        </div>
        <b-form
          v-on:keyup.enter="closeModal(saveDashboard(newDashboard))"
          @submit="closeModal(saveDashboard(newDashboard))"
          onsubmit="return false"
        >
          <h3 class="titreAjout">Modifier le nom du tableau de bord</h3>
          <b-row class="nomDashboard">
            <b-col cols="12">
              <b-form-input
                v-model="newDashboard.name"
                placeholder="Nom du tableau de bord"
                required
                style="text-align: center"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button type="submit" variant="danger">Enregistrer</b-button
              >&nbsp;&nbsp;&nbsp;
              <b-button @click="closeModal()" variant="danger"
                >Annuler</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <b-modal
        v-if="this.$route.name == 'Dashboard'"
        v-model="modalDashboardDescription"
        title="Description du tableau de bord"
        hide-header
        hide-footer
        class="ajout-dashboard"
        size="lg"
      >
        <div class="container-remove">
          <a class="icon-remove-graph">
            <font-awesome-icon @click="closeModal()" icon="times"
          /></a>
        </div>
        <b-form
          v-on:keyup.enter="closeModal(saveDashboard(newDashboard))"
          @submit="closeModal(saveDashboard(newDashboard))"
          onsubmit="return false"
        >
          <h3 class="titreAjout">Modifier la description du tableau de bord</h3>
          <b-row class="nomDashboard">
            <b-cols cols="12">
              <b-form-input
                v-model="newDashboard.description"
                placeholder="Description du tableau de bord"
                required
                style="text-align: center"
              ></b-form-input>
            </b-cols>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button type="submit" variant="danger">Enregistrer</b-button
              >&nbsp;&nbsp;&nbsp;
              <b-button @click="closeModal()" variant="danger"
                >Annuler</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div class="text-sidebar" v-if="this.$route.name == 'Dashboard'">
        <div class="dashboardNameSidebar">
          {{ dashboardSelected.name }}
        </div>
        <div class="dashboardDescSidebar">
          {{ dashboardSelected.description }}
        </div>
        <div class="years" v-if="this.roles != null && yearValue.lenght > 0">
          Période de {{ yearValue[0] }} à {{ yearValue[1] }}
        </div>
        <div class="zones" v-if="zoneValue.length != 0">
          Zones : <br />
          <span v-for="zone in zoneValue" :key="zone.name">
            - {{ zone.name }}<br />
          </span>
        </div>

        <hr class="hr-sidebar" />
      </div>

      <div
        v-b-visible="visibleHandler"
        class="position-fixed d-block d-lg-none"
      ></div>

      <div class="myDashboards">
        <router-link
          :to="{ name: 'Dashboards' }"
          class="btn-sidebar sidebar-link sidebar-bloc"
        >
          <font-awesome-icon
            icon="th-large"
            class="dashboards-icon"
            id="popover-side-dashboard"
          />
          <span class="txtMyDashboards" v-show="!drawerStatus"> Mes tableaux de bord</span>
        </router-link>
        <b-popover 
          v-if="drawerStatus"
          target="popover-side-dashboard"
          triggers="hover"
          placement="right"
        >
          Mes tableaux de bord
        </b-popover>
      </div>

      <div class="newsGraphs">
        <router-link
          :to="{ name: 'News' }"
          class="btn-sidebar sidebar-link sidebar-bloc notification"
        >
          <font-awesome-icon
            class="news-icon"
            icon="bell"
            id="popover-actu-dashboard"
          />
          <span class="txtNewsGraphs" v-show="!drawerStatus"> Nouveautés</span>
          <span class="badge">1</span>
        </router-link>
        <b-popover 
          v-if="drawerStatus"
          target="popover-actu-dashboard"
          triggers="hover"
          placement="right"
        >
          Nouveautés
        </b-popover>
      </div>

      <div class="addGraph" v-show="roles == 'ROLE_SUPER_ADMINISTRATEUR'">
        <router-link
          :to="{ name: 'AddGraph' }"
          class="btn-sidebar sidebar-link sidebar-bloc"
        >
          <font-awesome-icon class="addBtn" icon="plus" />
          <span class="txtAddGraph" v-show="!drawerStatus">Création graphique</span>
        </router-link>
        <b-popover 
          v-if="drawerStatus"
          target="popover-side-dashboard"
          triggers="hover"
          placement="right"
        >
        Création graphique
        </b-popover>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
export default {
  data: () => ({
    modalDashboardName: false,
    modalDashboardDescription: false,
    modalGraph: false,
    isXsSmMd: false,
    roles: null,
    menuUrba: false,
  }),
  watch: {
    isXsSmMd: {
      handler: function (val) {
        this.setDrawer(val);
      },
    },
  },
  async mounted() {
    if (this.$route.params.token) {
      let content = document.querySelector("#content");
      content.style.padding = "0px";
  } 
    //TODO: set dashboard with id
    // await this.getUserConnected();
    this.roles = this.userConnected.roles[1];
    // console.log(this.zoneValue);
},
  computed: {
    dashboardSelected: {
      get() {
        return this.dashboard;
      },
    },
    newDashboard: {
      get() {
        return this.dashboard;
      },
      set(value) {
        this.setDashboard(value);
      },
    },
    ...Vuex.mapGetters("Dossier", ["dosConnected"]),
    ...Vuex.mapGetters("Graph", ["graphs"]),
    ...Vuex.mapGetters("Authentification", ["drawerStatus", "userConnected", "tokenLectureSeule"]),
    ...Vuex.mapGetters("Dashboard", ["dashboard", "yearValue", "zoneValue", "yearMin",
      "yearMax",
      "yearValue",]),
  },
  methods: {
    visibleHandler(isVisible) {
      this.isXsSmMd = isVisible;
    },
    doUpdateDashboardName() {
      this.updateDashboardName(this.newDashboard);
      this.modalDashboardName = false;
    },
    closeModal() {
      this.modalDashboardName = false;
      this.modalDashboardDescription = false;
    },
    saveDashboard() {
      this.modalDashboardName = false;
      this.modalDashboardDescription = false;
    },
    afficherMenuUrbanisme() {
      if (this.menuUrba) {
        this.menuUrba = false;
      } else {
        this.menuUrba = true;
      }
    },
    ...Vuex.mapActions("Dossier", ["getDosConnected"]),
    ...Vuex.mapActions("Graph", ["getGraphs", 'addGraph']),
    ...Vuex.mapActions("Dashboard", ["saveDashboard", "addGraph", "getConnexions"]),
    ...Vuex.mapActions("Authentification", ["setDrawer", "getUserConnected", "setTokenLectureSeule"]),
  },
};
</script>
