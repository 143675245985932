import router from "../router";
import Vue from "vue";


const Communes = {
    namespaced: true,
    state: {
        communes: [],
    },
    actions: {
        getCommunes(context) {
            return new Promise((resolve) => {
                Vue.axios.get(window.adresseAPI + "ads/communes").then(
                    response => {
                        context.commit("GETGRAPHS", response.data.data);
                        resolve();
                    }
                );
            })
        },
    },
    mutations: {
        GETGRAPHS: (state, communes) => {
            state.communes = communes;
        }
    },
    getters: {
        communes: state => state.communes
    }
}
export default Communes;