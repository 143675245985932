<template>
  <div>
    <b-button
      @click="resetModal(), showModal()"
      class="btn-add-graph-toolbar"
      id="popover-dashboard"
    >
      <font-awesome-icon icon="plus" />
      <span v-show="!drawerStatus" class="btn-header-text">
        Ajouter un tableau de bord</span
      >
    </b-button>
    <b-modal
    no-close-on-backdrop
      ref="modalAjout"
      v-b-modal.modal-center
      v-if="$route.name == 'Dashboards'"
      v-model="modalDashboard"
      title="Dashboards"
      hide-header
      hide-footer
      class="ajout-dashboard"
      size="lg"
    >
      <div class="cookiesContent" id="cookiesPopup">
        <button class="close" @click="hideModal">✖</button>
        <h3>Ajout d'un tableau de bord</h3>
        <b-form
          v-on:keyup.enter="doAddDashboard"
          @submit="doAddDashboard"
          onsubmit="return false"
        >
          <b-row class="nomDashboard">
            <b-col>
              <b-form-input
                autocomplete="off"
                v-model="newDashboard.name"
                placeholder="Nom du tableau de bord"
                required
                style="text-align: center"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="descriptionDashboard">
            <b-col>
              <b-form-textarea
                autocomplete="off"
                v-model="newDashboard.description"
                placeholder="Description du tableau de bord"
                required
                style="text-align: center"
              ></b-form-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              ><button type="submit" class="button-sup-modal">
                Ajouter
              </button></b-col
            >
            <b-col
              ><button class="button-cancel-modal" @click="hideModal">
                Annuler
              </button></b-col
            >
          </b-row>
        </b-form>
      </div>
    </b-modal>
    <!-- <b-modal
            style="padding: 80px;"
            v-if="$route.name == 'Dashboards'"
            v-model="modalDashboard"
            title="Dashboards"
            hide-header
            hide-footer
            class="ajout-dashboard"
            size="lg"
            >
            <div class="container-remove">
                <a class="icon-remove-graph">
                <font-awesome-icon @click="closeModal()" icon="times"
                /></a>
            </div>
            <b-form
                v-on:keyup.enter="doAddDashboard"
                @submit="doAddDashboard"
                onsubmit="return false"
            >
                <h3 class="titreAjout">Ajout d'un tableau de bord</h3>
                <b-row class="nomDashboard">
                <b-col cols="12">
                    <b-form-input
                    v-model="newDashboard.name"
                    placeholder="Nom du tableau de bord"
                    required
                    style="text-align: center"
                    ></b-form-input>
                </b-col>
                </b-row>
                <b-row class="descriptionDashboard">
                <b-col cols="12">
                    <b-form-textarea
                    v-model="newDashboard.description"
                    placeholder="Description du tableau de bord"
                    required
                    style="text-align: center"
                    ></b-form-textarea>
                </b-col>
                </b-row>
                <b-row>
                <b-col cols="12">
                    <b-button type="submit" class="button-enregistrer">Enregistrer</b-button
                    >&nbsp;&nbsp;&nbsp;
                    <b-button @click="closeModal()" class="button-appliquer"
                    >Annuler</b-button
                    >
                </b-col>
                </b-row>
            </b-form>
        </b-modal> -->
  </div>
</template>

<script>
import Vuex from "vuex";
export default {
  name: "AddDashboard",

  data: () => ({
    modalDashboard: false,
    newDashboard: {
      name: "",
      description: "",
    },
  }),
  methods: {
    ...Vuex.mapActions("Dashboard", ["addDashboard"]),
    closeModal() {
      this.modalDashboard = false;
      this.modalGraph = false;
    },
    resetModal() {
      this.newDashboard.name = "";
      this.newDashboard.description = "";
    },
    doAddDashboard() {
      this.addDashboard(this.newDashboard);
      this.modalDashboard = false;
    },
    showModal() {
      this.$refs["modalAjout"].show();
    },
    hideModal() {
      this.$refs["modalAjout"].hide();
    },
  },

  computed: {
    ...Vuex.mapGetters("Authentification", ["drawerStatus"]),
  },
};
</script>
