<template>
    <div>
        <b-card
            title="Connexion Dossier Expiré"
            tag="article"
            style="max-width: 30rem; text-align: center; position: relative; margin-top: 10%; margin-left: 30%;"
            class="card-no-dos"
        >
            <b-card-text>
            Il semble que vous n'êtes actuellement connecté à aucun dossier. Vous devez être connecté à un environnement pour accéder à l'outil de statistiques.
            </b-card-text>

            <b-button href="https://www.clicmap.fr/" variant="button-appliquer">Reconnexion</b-button>
        </b-card>
    </div>
</template>

<script>
    import Vue from "vue";
    import Vuex from "vuex";
    export default {
        name: "NoDosConnected",
        data: () => ({

        }),
    };
</script>

