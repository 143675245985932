import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Authentification from './stores/Authentification.js';
import Dashboard from './stores/Dashboard.js';
import Commune from './stores/Commune.js';
import Dossier from './stores/Dossier.js';
import Graph from './stores/Graph.js';

export default new Vuex.Store({
  namespaced: true,
  modules: {
    'Authentification' : Authentification,
    'Dashboard' : Dashboard,
    'Commune' : Commune,
    'Dossier' : Dossier,
    'Graph' : Graph
  }
});
