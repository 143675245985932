import Vue from "vue";
import VueRouter from "vue-router";
import Dashboards from "../views/Dashboards.vue";
import Dashboard from "../views/Dashboard.vue";
import NoLogin from "../views/NoLogin.vue";
import NoDosConnected from "../views/NoDosConnected.vue";
import NotFound from "../views/NotFound.vue";
import Settings from "../views/Settings.vue";
import News from "../views/News.vue";
import ComingSoon from "../views/ComingSoon.vue";
import AddGraph from "../views/AddGraph.vue";
import ModifyGraph from "../views/ModifyGraph.vue";
import Login from "../views/Login.vue";
import Env from "../views/Env.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "tableaux-de-bord",
  },
  {
    name: 'Env',
    path: "/env/:id",
    component: Env,
  },
  {
    path: "/tableau-de-bord/:id",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Statistiques",
      requiresAuth: true,
    },
  },
  {
    path: "/tableau-de-bord/partage/:token",
    name: "Lecture",
    component: Dashboard,
    meta: {
      requiresAuth: false,
      title: "Statistiques",
    },
  },
  {
    path: "/tableaux-de-bord",
    name: "Dashboards",
    component: Dashboards,
    meta: {
      title: "Tableaux de bord",
      requiresAuth: true,
    },
  },
  {
    path: "/expire",
    name: "NoLogin",
    component: NoLogin,
    meta: {

      title: "Statistiques | Connexion expirée",
    },
  },
  {
    path: "/parametres/:id",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      title: "Paramètres",
    },
  },
  {
    path: "/graphs/:id",
    name: "ModifyGraph",
    component: ModifyGraph,
    meta: {
      requiresAuth: true,
      title: "Modifier un graphique",}
  },
  {
    path: "/ajouter-graphique",
    name: "AddGraph",
    component: AddGraph,
    meta: {
      requiresAuth: true,
      title: "Créer un graphique",
    },
  },
  {
    path: "/actualites",
    name: "News",
    component: News,
    meta: {
      requiresAuth: true,
      title: "Actualités",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      title: "Statistiques | Login",
    },
  },
  {
    path: "/DosExpire",
    name: "NoDosConnected",
    component: NoDosConnected,
    meta: {
      requiresAuth: false,
      title: "Statistiques | Connexion dossier expirée",
    },
  },
  {
    path: "/BientotDisponible",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      requiresAuth: false,
      title: "Statistiques | Bîentot disponible",
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Statistiques | Page non trouvée",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
