import router from "../router";
import Vue from "vue";

const Dashboard = {
    namespaced: true,
    state: {
        valueDB: {
            id: null,
            nom_database: null
        },
        dashboards: [],
        addedGraph: false,
        yearMin: null,
        yearMax: null,
        numberMin: null,
        dashboard: {
            data: [],
            id: null,
            name: null,
            description: null,
        },
        multiSelectValue: [],
        showFilter: false,
        showAddElement: false,
        showModalePanneau: false,
        showModaleSeparateur: false,
        showModaleGraphs: false,
        yearValue: [],
        datesValue: [],
        zoneValue: [],
        idPanneau: 500,
        isInterCo: false,
    },

    actions: {
        setZoneValue(context, zoneValue) {
            context.commit("SETZONEVALUE", zoneValue);
        },
        setYearValue(context, yearValue) {
            context.commit("SETYEARVALUE", yearValue);
        },
        setDatesValue(context, datesValue) {
            context.commit("SETDATESVALUE", datesValue);
        },
        setShowFilter(context, showFilter) {
            context.commit("SETSHOWFILTER", showFilter);
        },
        setShowAddElement(context, showAddElement) {
            context.commit("SETSHOWADDELEMENT", showAddElement)
        },
        setShowModalePanneau(context, showModalePanneau) {
            context.commit("SETSHOWMODALEPANNEAU", showModalePanneau)
        },
        setShowModaleSeparateur(context, showModaleSeparateur) {
            context.commit("SETSHOWMODALESEPARATEUR", showModaleSeparateur)
        },
        setShowModaleGraphs(context, showModaleGraphs) {
            context.commit("SETSHOWMODALEGRAPHS", showModaleGraphs)
        },
        setAddedGraph(context, addedGraph) {
            context.commit("SETADDEDGRAPH", addedGraph);
        },
        setDashboard(context, dashboard) {
            context.commit("SETDASHBOARD", dashboard);
        },
        setDashboardId(context, dashboard) {
            context.commit('SETDASHBOARDID', dashboard)
        },
        getDashboards(context) {
            return new Promise((resolve) => {
                Vue.axios.get(window.adresseAPI + "stats/dashboards").then(
                    response => {
                        context.commit("GETDASHBOARDS", response.data.data);
                        resolve();
                    }
                );
            })
        },
        getAnneeMinMax(context) {
            return new Promise((resolve) => {
                var url = window.adresseAPI + "stats/anneeDonnee" + (context.rootGetters['Authentification/tokenLectureSeule'] != null ?  '/' + context.rootGetters['Authentification/tokenLectureSeule'] : '');
                Vue.axios.get(url).then(
                    response => {
                        context.commit("GETANNEEMINMAX", response.data.data);
                        resolve();
                    }
                );
            })
        },
        setDashboardById(context, id) {
            var url = window.adresseAPI + "stats/dashboard/" + id + (context.rootGetters['Authentification/tokenLectureSeule'] != null ?  '/' + context.rootGetters['Authentification/tokenLectureSeule'] : '');
            return new Promise((resolve) => {
                Vue.axios.get(url).then(
                    response => {
                        // console.log(JSON.parse(response.data.data.data))
                        context.commit("SETDASHBOARDID", response.data.data);
                        resolve();
                    }
                );
            })
        },
        getShareLink(context, id) {
            return new Promise((resolve) => {
                Vue.axios.get(window.adresseAPI + "stats/share/" + id).then(
                    response => {
                        resolve(response.data.data);
                    }
                );
            })
        },
        getNombreDossiers(context) {
            return new Promise((resolve) => {
                Vue.axios.get(window.adresseAPI + "stats/nombreDonnee").then(
                    response => {
                        context.commit("GETNOMBREDOSSIERS", response.data.data);
                        resolve();
                    }
                );
            })
        },
        getMultiSelectValue(context) {
            return new Promise((resolve) => {
                var url = window.adresseAPI + "stats/multiSelectValue" + (context.rootGetters['Authentification/tokenLectureSeule'] != null ?  '/' + context.rootGetters['Authentification/tokenLectureSeule'] : '');
                Vue.axios.get(url).then(
                    response => {
                        // console.log(response.data)
                        context.commit("GETMULTISELECTVALUE", response.data);
                        resolve();
                        var isFind = false
                        for(var i = 0; i < response.data.data.length && isFind != true; i++) {
                            if(response.data.data[i].name == response.data.msg) {
                                isFind = true
                            }
                        }
                        context.commit("SETISINTERCO", !isFind);
                        resolve();
                    }
                );
            })
        },
        addDashboard(context, dashboard) {
            return new Promise((resolve) => {
                Vue.axios.post(window.adresseAPI + "stats/dashboard", dashboard).then(
                    response => {
                        if (response.data.success == true) {
                            context.commit("ADDDASHBOARD", response.data.data);
                            Vue.prototype.$awn.success(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 3000
                                },
                            );
                        } else {
                            Vue.prototype.$awn.alert(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 3000
                                }
                            );
                        }
                        resolve();
                    }
                );
            })
        },

        saveDashboard(context, dashboard) {
            return new Promise((resolve) => {
                Vue.axios.put(window.adresseAPI + "stats/dashboard/" + dashboard.id, dashboard).then(
                    response => {
                        if (response.data.success == true) {
                            Vue.prototype.$awn.success(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        } else {
                            Vue.prototype.$awn.alert(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        }
                        // context.commit("SAVEGRAPHSDASHBOARDS", response.data.data);
                        resolve();
                    }
                );
            })
        },

        shareDashboard(context, id) {
            return new Promise((resolve) => {
                Vue.axios.put(window.adresseAPI + "stats/share/" + id).then(
                    response => {
                        if (response.data.success == true) {
                            Vue.prototype.$awn.success(
                                "Tableau de bord partagé avec succès",
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        } else {
                            Vue.prototype.$awn.alert(
                                "Une erreur est survenue lors du partage du tableau de bord",
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        }
                        // context.commit("SAVEGRAPHSDASHBOARDS", response.data.data);
                        resolve();
                    }
                );
            })
        },
        deleteDashboard(context, id) {
            return new Promise((resolve) => {
                Vue.axios.delete(window.adresseAPI + "stats/dashboard/" + id).then(
                    response => {
                        if (response.data.success == true) {
                            Vue.prototype.$awn.success(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 3000
                                }
                            );
                            // context.commit("DELETEDASHBOARD", dashboard);
                        } else {
                            Vue.prototype.$awn.alert(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 3000
                                }
                            );
                        }
                        resolve();
                    }
                );
            })
        },
        addGraph(context, graph) {
            context.commit("ADDGRAPH", graph);
        },
        addSeparator(context, separator) {
            context.commit("ADDSEPARATOR", separator);
        },
        addPanneau(context, pannneau) {
            context.commit("ADDPANNEAU", pannneau);
        },
        removeGraph(context, id) {
            context.commit("REMOVEGRAPH", id);
        },
    },
    mutations: {
        SETISINTERCO: (state, isInterCo) => {
            state.isInterCo = isInterCo
        },
        SETYEARVALUE: (state, yearValue) => {
            state.yearValue = yearValue;
        },
        SETDATESVALUE: (state, datesValue) => {
            state.datesValue = datesValue;
        },
        SETZONEVALUE: (state, zoneValue) => {
            state.zoneValue = zoneValue;
        },
        SETSHOWFILTER: (state, showFilter) => {
            state.showFilter = showFilter;
        },
        SETSHOWADDELEMENT: (state, showAddElement) => {
            state.showAddElement = showAddElement
        },
        SETSHOWMODALEPANNEAU: (state, showModalePanneau) => {
            state.showModalePanneau = showModalePanneau
        },
        SETSHOWMODALESEPARATEUR: (state, showModaleSeparateur) => {
            state.showModaleSeparateur = showModaleSeparateur
        },
        SETSHOWMODALEGRAPHS: (state, showModaleGraphs) => {
            state.showModaleGraphs = showModaleGraphs
        },
        SETDASHBOARDBYID: (state, dashboard) => {
            state.dashboard = dashboard;
        },
        SETADDEDGRAPH: (state, addedGraph) => {
            state.addedGraph = addedGraph;
        },
        GETCONNEXIONS: (state, valueDB) => {
            state.valueDB = valueDB;
        },
        SETDASHBOARD: (state, dashboard) => {
            state.dashboard = dashboard;
        },
        GETDASHBOARDS: (state, dashboards) => {
            state.dashboards = dashboards;
        },
        SETDASHBOARDID: (state, dashboard) => {
            state.dashboard = dashboard;
        },
        GETANNEEMINMAX: (state, anneeMinMax) => {
            state.yearMin = anneeMinMax["min"];
            state.yearMax = anneeMinMax["max"];
        },
        GETNOMBREDOSSIERS: (state, nombreDossiers) => {
            state.numberMin = nombreDossiers["nombre"];
        },
        GETMULTISELECTVALUE: (state, multiSelectValue) => {
            state.multiSelectValue = multiSelectValue;
        },

        ADDDASHBOARD: (state, dashboard) => {
            state.dashboards.push(dashboard);
            // console.log(dashboard)
        },

        ADDGRAPH: (state, dashboard) => {
            let index = state.dashboard.data.findIndex(element => element.i == dashboard.i);
            if (index < 0) {
                state.dashboard.data[0].graphs.push(dashboard);
                state.addedGraph = dashboard.i;
            }
        },
        ADDPANNEAU: (state, dashboard) => {
            let index = state.dashboard.data.findIndex(element => element.name == dashboard.name);
            if (index < 0) {
                state.dashboard.data[0].graphs.push(dashboard);
                state.addedGraph = dashboard.name
                dashboard.i = dashboard.name
            }
        },
        ADDSEPARATOR: (state, dashboard) => {
            let index = state.dashboard.data.findIndex(element => element.name == dashboard.name);
            if (index < 0) {
                state.dashboard.data.push({
                    name: dashboard,
                    graphs: [],
                });
            }
        },
        REMOVEGRAPH: (state, dashboard) => {
            for (let i = 0; i < state.dashboard.data.length; i++) {
                for (let i2 = 0; i2 < state.dashboard.data[i].graphs.length; i2++) {
                    if (state.dashboard.data[i].graphs[i2].i == dashboard) {
                        state.dashboard.data[i].graphs.splice(i2, 1);
                    }
                }
            }
        },
        DELETEDASHBOARD: (state, dashboard) => {
            let index = state.dashboards.findIndex(element => element.id == dashboard.id);
            state.dashboards.splice(index, 1);
        },
    },
    getters: {
        valueDB: state => state.valueDB,
        dashboard: state => state.dashboard,
        countDashboard: state => state.dashboard.data.length,
        dashboards: state => state.dashboards,
        addedGraph: state => state.addedGraph,
        yearMin: state => state.yearMin,
        yearMax: state => state.yearMax,
        numberMin: state => state.numberMin,
        multiSelectValue: state => state.multiSelectValue,
        showFilter: state => state.showFilter,
        showAddElement: state => state.showAddElement,
        showModalePanneau: state => state.showModalePanneau,
        showModaleSeparateur: state => state.showModaleSeparateur,
        showModaleGraphs: state => state.showModaleGraphs,
        yearValue: state => state.yearValue,
        zoneValue: state => state.zoneValue,
        isInterCo: state => state.isInterCo,
        datesValue: state => state.datesValue,
    }
}
export default Dashboard;