<template>
  <b-row class="content-dashboards">
    <b-col v-for="dashboard in dashboards" :key="dashboard.id">
      <div class="container-card">
        <b-card
          v-if="dashboard.shared_by != null"
          :title="dashboard.name"
          :sub-title="dashboard.description"
          class="mb-21"
          style="text-align: left"
          border-variant="danger"
          :header="'Tableau de bord partagé par ' + dashboard.pseudo"
          header-border-variant="danger"
          header-text-variant="danger"
        />
        <b-card
          v-else
          :title="dashboard.name"
          :sub-title="dashboard.description"
          class="mb-21"
          style="text-align: left"
        />
        
        <div class="hover-card" @click="toDashboard(dashboard.id)">
          <font-awesome-icon icon="eye"></font-awesome-icon>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Vuex from "vuex";
import router from "../router/index";

export default {
  data() {
    return {
      items: [{ nom: "", description: "" }],
    };
  },

  async mounted() {
    window.Event.$emit("before-request");
    await this.getDashboards();
    window.Event.$emit("after-response");
  },
  computed: {
    ...Vuex.mapGetters("Dashboard", ["dashboards"]),
  },
  methods: {
    toDashboard(id) {
      router.push({
        name: "Dashboard",
        params: { id: id },
      });
    },
    ...Vuex.mapActions("Dashboard", ["getDashboards"]),
  },
};
</script>
