<template>
  <div class="row-buttons-dashboard">

    <b-modal
      no-close-on-backdrop
      v-if="$route.name == 'Dashboard'"
      v-model="showModaleGraphs"
      title="Graphiques"
      hide-header
      hide-footer
      size="lg"
      class="ajout-graph-modale"
    >
      <div class="search-graph" style="padding: 15px !important">
        <font-awesome-icon class="search-icon" icon="search" />
        <button
          class="close"
          @click="setShowModaleGraphs((showModaleGraphs = !showModaleGraphs))"
        >
          ✖
        </button>
        <b-form-input
          class="search-graph-input"
          type="text"
          v-model="search.searchText"
          placeholder="Rechercher..."
        />
      </div>
      <div
        v-for="value in graphs"
        :key="value.id"
        style="padding: 15px !important"
      >
        <b-button
          @click="addItem(value)"
          variant="light"
          class="bouton-ajout-graph"
        >
          <div class="img-graph">
            <img :src="require('../assets/' + value.img)" />
          </div>
          <div class="ajout-graph-texte">
            <div class="ajout-graph-nom">{{ value.name }}</div>
            <div class="ajout-graph-description">
              {{ value.description }}
            </div>
          </div>
        </b-button>
        <b-button
          v-show="roles == 'ROLE_SUPER_ADMINISTRATEUR'"
          @click="getGraphClicked(value)"
          class="bouton-modif-graph"
          ><font-awesome-icon icon="cogs"
        /></b-button>
      </div>
    </b-modal>

    <b-modal
      no-close-on-backdrop
      v-if="$route.name == 'Dashboard'"
      v-model="showModalePanneau"
      title="Graphiques"
      hide-header
      hide-footer
      size="lg"
      class="ajout-graph"
    >
      <div class="cookiesContent" id="cookiesPopup">
        <button
          class="close"
          @click="
            setShowModalePanneau((showModalePanneau = !showModalePanneau))
          "
        >
          ✖
        </button>
        <h3>Ajout d'un panneau</h3>
        <div class="div-modal-input">
          <div class="nom-panneau">Nom du panneau</div>
          <b-form onsubmit="return false">
            <b-row class="nomDashboard">
              <b-col>
                <b-form-input
                  autocomplete="off"
                  v-model="nomPanneau"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><button
                  type="submit"
                  class="button-sup-modal"
                  @click="
                    addItemPanneau(nomPanneau),
                      setShowModalePanneau(
                        (showModalePanneau = !showModalePanneau)
                      )
                  "
                >
                  Ajouter
                </button></b-col
              >
              <b-col
                ><button
                  class="button-cancel-modal"
                  @click="
                    setShowModalePanneau(
                      (showModalePanneau = !showModalePanneau)
                    )
                  "
                >
                  Annuler
                </button></b-col
              >
            </b-row>
          </b-form>
        </div>
      </div>
    </b-modal>

    <b-modal
      no-close-on-backdrop
      v-if="$route.name == 'Dashboard'"
      v-model="showModaleSeparateur"
      title="Graphiques"
      hide-header
      hide-footer
      size="lg"
      class="ajout-graph"
    >
      <div class="cookiesContent" id="cookiesPopup">
        <button
          class="close"
          @click="
            setShowModaleSeparateur(
              (showModaleSeparateur = !showModaleSeparateur)
            )
          "
        >
          ✖
        </button>
        <h3>Ajout d'un séparateur</h3>
        <div class="div-modal-input">
          <div class="nom-panneau">Nom du séparateur</div>
          <b-form onsubmit="return false">
            <b-row class="nomDashboard">
              <b-col>
                <b-form-input
                  autocomplete="off"
                  v-model="nomSperateur"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><button
                  type="submit"
                  class="button-sup-modal"
                  @click="
                    addItemGridview(nomSperateur),
                      setShowModaleSeparateur(
                        (showModaleSeparateur = !showModaleSeparateur)
                      )
                  "
                >
                  Ajouter
                </button></b-col
              >
              <b-col
                ><button
                  class="button-cancel-modal"
                  @click="
                    setShowModaleSeparateur(
                      (showModaleSeparateur = !showModaleSeparateur)
                    )
                  "
                >
                  Annuler
                </button></b-col
              >
            </b-row>
          </b-form>
        </div>
      </div>
    </b-modal>
    <b-popover
      v-if="window.width <= 1610"
      class="header-popover"
      target="popover-dashboard"
      triggers="hover"
      placement="bottom"
    >
      Ajouter un élément
    </b-popover>
    <b-dropdown
      v-if="
      ($route.name == 'Dashboard' &&
          this.userConnected.id_membre == this.dashboardSelected.shared_by) ||
          this.dashboardSelected.shared_by == null && !readOnly
      "
      id="dropdown-1"
      text="+ Ajouter un élément"
      class="dropdown-addGraphs mr-3"
    >
      <b-dropdown-item-button
        @click="
          setShowModaleSeparateur(
            (showModaleSeparateur = !showModaleSeparateur)
          )
        "
      >
        Separateur
      </b-dropdown-item-button>
      <b-dropdown-item-button
        @click="setShowModalePanneau((showModalePanneau = !showModalePanneau))"
      >
        Panneau
      </b-dropdown-item-button>
      <b-dropdown-item-button
        @click="
          setShowModaleGraphs((showModaleGraphs = !showModaleGraphs)),
            eventListener()
        "
      >
        Graphique
      </b-dropdown-item-button>
    </b-dropdown>

    <b-button
      v-if="
        ($route.name == 'Dashboard' &&
          this.roles != null &&
          dashboardSelected.app != null) || readOnly
      "
      @click="setShowFilter(!showFilter)"
      class="btn-add-graph-toolbar filter-btn"
      id="popover-filter"
    >
      <font-awesome-icon icon="filter" />
      <span v-show="!drawerStatus" class="btn-header-text"> Filtrer</span>
    </b-button>
    <b-popover
      v-if="window.width <= 1610 && dashboardSelected.app != null"
      class="header-popover"
      target="popover-filter"
      triggers="hover"
      placement="bottom"
    >
      Filtrer les données
    </b-popover>
    <b-button
      v-if="
        ($route.name == 'Dashboard' &&
          this.userConnected.id_membre == this.dashboardSelected.shared_by) ||
          this.dashboardSelected.shared_by == null && !readOnly
      "
      @click="saveDashboard(dashboardSelected)"
      class="btn-add-graph-toolbar"
      id="popover-save"
    >
      <font-awesome-icon icon="save" />
      <span v-show="!drawerStatus" class="btn-header-text"> Sauvegarder </span>
    </b-button>
    <b-popover
      v-if="window.width <= 1610"
      class="header-popover"
      target="popover-save"
      triggers="hover"
      placement="bottom"
    >
      Sauvegarder
    </b-popover>
    <b-button
      v-if="
        ($route.name == 'Dashboard' &&
          this.userConnected.id_membre == this.dashboardSelected.shared_by) ||
          this.dashboardSelected.shared_by == null && !readOnly
      "
      @click="goSettings"
      class="btn-add-graph-toolbar"
      id="popover-settings"
    >
      <font-awesome-icon icon="cog" />
      <span v-show="!drawerStatus" class="btn-header-text"></span>
    </b-button>
    <b-popover
      v-if="window.width <= 1610"
      class="header-popover"
      target="popover-settings"
      triggers="hover"
      placement="bottom"
    >
      Paramètres
    </b-popover>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
export default {
  name: "AddGraphs",
  data: () => ({
    readOnly: false,
    valueSelected: null,
    nbGridLayout2: 10,
    countPanneau: 5000,
    showInput: false,
    showInputDescription: false,
    modalPanneau: false,
    modalSeparateur: false,
    modalGraph: false,
    isXsSmMd: false,
    nomSperateur: "",
    modalDashboard: false,
    roles: null,
    search: {
      searchText: "",
      searchTag: [],
      id: "",
    },
    window: {
      width: 0,
      height: 0,
    },
    newDashboard: {
      name: "",
      description: "",
    },
    nomPanneau: "",
    selected: [],
    res: "",
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.$root.$refs.AddGraphs = this;
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  watch: {
    search: {
      deep: true,
      handler: function(val) {
        this.getGraphs(this.search);
      },
    },
  },
  computed: {
    ...Vuex.mapGetters("Graph", ["graphs", "types"]),
    ...Vuex.mapGetters("Authentification", ["drawerStatus", "userConnected"]),
    ...Vuex.mapGetters("Dashboard", [
      "dashboard",
      "showFilter",
      "showAddElement",
      "dashboards",
      "showModalePanneau",
      "showModaleSeparateur",
      "showModaleGraphs",
    ]),
    dashboardSelected: {
      get() {
        return this.dashboard;
      },
      set(value) {
        this.setDashboard(value);
      },
    },
  },
  methods: {
    getGraphClicked(value) {
      this.$router.push({ name: "ModifyGraph", params: { id: value.id } });
    },
    ...Vuex.mapActions("Graph", ["getGraphs", "addGraph"]),
    ...Vuex.mapActions("Dashboard", [
      "setShowModaleGraphs",
      "setShowModaleSeparateur",
      "setShowModalePanneau",
      "addSeparator",
      "addPanneau",
      "saveDashboard",
      "deleteDashboard",
      "addGraph",
      "getDashboards",
      "setShowFilter",
      "setShowAddElement",
    ]),
    ...Vuex.mapActions("Authentification", ["setDrawer", "getUserConnected"]),
    closeModal() {
      this.modalDashboard = false;
      this.modalGraph = false;
      this.modalPanneau = false;
      this.modalSeparateur = false;
    },
    goSettings() {
      this.$router.push({ name: "Settings" });
    },
    addItem: function(value) {
      // let self = this;
      let item = {
        x: 0,
        y: 0,
        w: 6,
        h: 9,
        i: value.id,
        isGraph: true,
        numSep: 2,
        img: value.img,
        icon: value.icon,
        name: value.name,
        description: value.description,
        id_database: value.id_database,
      };
      this.addGraph(item);
    },
    addItemPanneau: function(nom) {
      this.countPanneau = this.countPanneau + 1;
      // let self = this;
      let item = {
        x: 0,
        y: 0,
        w: 6,
        h: 3,
        i: null,
        isGraph: false,
        numSep: 1,
        img: null,
        icon: null,
        name: nom,
        description: null,
        id_database: null,
      };
      this.addPanneau(item);
      this.nomPanneau = null;
    },
    addItemGridview: function(nom) {
      this.countPanneau = this.countPanneau + 1;
      this.addSeparator(nom);
      this.nomPanneau = null;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  async mounted() {
    if (this.$route.params.token) {
      this.readOnly = true;
      let content = document.querySelector("#content");
      content.style.padding = "0px";
    }
    else {
      await this.getUserConnected();
    this.roles = this.userConnected.roles[1];
    }
    this.setShowModaleSeparateur((this.showModaleSeparateur = false));
    this.setShowModalePanneau((this.showModalePanneau = false));
    this.setShowModaleGraphs((this.showModaleGraphs = false));
    
    this.getGraphs(this.search);
    this.getTagsGraphs();
    this.getTypeGraphs();
    this.getGraph(this.$route.params.id);
  },
};
</script>
