<template>
    <div>
        chargement...
    </div>
</template>

<script>
    import Vue from "vue";
    import Vuex from "vuex";
    import router from "../router/index";
    export default {
        name: "goTonv",
        data: () => ({

        }),
        computed : {
            ...Vuex.mapGetters('Authentification',['isAuthenticated'])
        },
        methods : {
            ...Vuex.mapActions('Authentification',['setEnv']),
            ...Vuex.mapActions('Dossier',['getDosConnected'])
        },
        async mounted(){
                console.log(this.isAuthenticated);
            if(this.isAuthenticated == false){
                localStorage.setItem("back_url",window.location.pathname);
                router.push({name : 'Login'})
            }else{
                let id = this.$route.params.id
                await this.setEnv(this.$route.params.id);
                await this.getDosConnected();
                router.push({name : 'Dashboards'});
            }
            
        }
    };
</script>

