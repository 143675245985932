<template>
  <div id="header">
    <div class="logo">
      <img src="https://www.clicmap.fr/img/txt-logo.png" />
      <div id="toptitle" class="titre-page truncate">
        Statistiques : {{ dosConnected.title }}
      </div>
    </div>
    <div class="header-right">
      <add-dashboard v-if="this.$route.name == 'Dashboards'"></add-dashboard>
      <add-graphs v-show="this.$route.name == 'Dashboard' || this.$route.name == 'Lecture'"></add-graphs>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import AddDashboard from "../components/AddDashboard.vue";
import AddGraphs from "../views/AddGraphs.vue";
export default {
  name: "Toolbar",
  components: {
    "add-dashboard": AddDashboard,
    "add-graphs": AddGraphs,
  },
  data: () => ({
    showInput: false,
    showInputDescription: false,
    modalGraph: false,
    isXsSmMd: false,

    search: {
      searchText: "",
      searchTag: [],
      id: "",
    },
    window: {
      width: 0,
      height: 0,
    },

    selected: [],
    res: "",
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    search: {
      deep: true,
      handler: function (val) {
        this.getGraphs(this.search);
      },
    },
    isXsSmMd: {
      handler: function (val) {
        this.setDrawer(val);
      },
    },
  },
  computed: {
    dashboardSelected: {
      get() {
        return this.dashboard;
      },
      set(value) {
        this.setDashboard(value);
      },
    },
    ...Vuex.mapGetters("Dossier", ["dosConnected"]),
    ...Vuex.mapGetters("Dashboard", ["dashboard", "showFilter", "anneeMinMax", "yearMin", "yearMax", "yearValue"]),
    ...Vuex.mapGetters("Graph", ["graphs", "tags", "types", "addGraph", "sqlContent"]),
    ...Vuex.mapGetters("Authentification", ["drawerStatus"]),
  },
  methods: {
    visibleHandler(isVisible) {
      this.isXsSmMd = isVisible;
    },

    closeModal() {
      this.modalGraph = false;
    },
    goSettings() {
      this.$router.push({ name: "Settings" });
    },

    ...Vuex.mapActions("Dashboard", ["addGraph"]),
    deleteMyDashboard() {
      Vue.prototype.$awn.confirm(
        "Voulez-vous vraiement supprimer ce tableau de bord ?",
        () => {
          this.deleteDashboard(this.dashboardSelected);
          this.$router.push("/tableaux-de-bord");
        },
        () => {}
      );
    },
    showInputFunc() {
      this.showInput = !this.showInput;
    },
    showInputDescriptionFunc() {
      this.showInputDescription = !this.showInputDescription;
    },
    ...Vuex.mapActions("Dossier", ["getDosConnected"]),
    ...Vuex.mapActions("Dashboard", [
      "saveDashboard",
      "deleteDashboard",
      "addGraph",
      "getDashboards",
      "setShowFilter",
      "getAnneeMinMax",
    ]),
    ...Vuex.mapActions("Authentification", ["setDrawer"]),
    ...Vuex.mapActions("Graph", ["getGraphs", "getTagsGraphs", "getTypeGraphs", "addGraph", "getSqlContent"]),
    addItem: function (value) {
      // let self = this;
      //console.log("### LENGTH: " + this.layout.length);
      let item = {
        x: 0,
        y: 0,
        w: 6,
        h: 9,
        i: value.id,
        img: value.img,
        icon: value.icon,
        name: value.name,
        description: value.description,
      };
      this.addGraph(item);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  async mounted() {
    await this.getDosConnected();
    await this.getGraph(this.$route.params.id);
    this.getGraphs(this.search);
    this.getDashboards();
    this.getTagsGraphs();
    this.getAnneeMinMax();
  },
};
</script>
