import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/fontawesome'
import App from './App.vue'
import router from "./router";

import VueCookies from "vue-cookies";
import './config.js';
import './plugins/axios'


import store from "./store.js";


import * as echarts from 'echarts';

import VueSlider from 'vue-slider-component'
import VueGridLayout from 'vue-grid-layout';
import Multiselect from 'vue-multiselect'
Vue.prototype.$echarts = echarts;

//MODULES
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import {
  localize
} from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);
// install rules
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
import VueAWN from "vue-awesome-notifications";
let options = {
  position: "bottom-right",
  labels: {
    info: "Information",
    success: "Succès",
    warning: "Attention",
    alert: "Erreur",
    async: "Chargement...",
    confirm: "Attention",
    confirmOk: "Valider",
    confirmCancel: "Annuler",
  },
};
Vue.use(VueAWN, options);


Vue.component('GridLayout', VueGridLayout.GridLayout)
Vue.component('GridItem', VueGridLayout.GridItem)
Vue.component('multiselect', Multiselect)
Vue.component('VueSlider', VueSlider)
Vue.config.productionTip = false
Vue.use(VueCookies);



const DEFAULT_TITLE = "Statistiques";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if ((requiresAuth && !store.getters["Authentification/isAuthenticated"])) {
      console.log(window.location);
     next("/login");
  } else {

    if (
      (store.getters["Authentification/isAuthenticated"] &&
      !store.getters["Authentification/isUserConnected"])
    ) {
      Vue.axios.get(window.adresseAPI + "user").then((response) => {
        if (typeof response.data.data.id_membre !== "undefined") {
          store.dispatch("Authentification/setUserConnected", response.data.data);
          next();
        } else {
          console.log(window.location);
          next("/login");
        }
      });
    }

     next();
   }
 
});
 

  //controle sur l'utilisateur connected


window.Event = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
