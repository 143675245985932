<template>
  <ValidationObserver v-slot="{ invalid }">
    <div class="content-news">
      <!-- div-gauche si le graphique n'est pas un tableau -->
      <div class="div-gauche" v-show="!showTable">
          <button type="submit" class="button-annuler" onclick="history.back()">Annuler</button>
        <div :ref="graphSelected.id" class="content-bloc2"></div>
      </div>
      <!-- div-gauche si le graphique est un tableau -->
      <div class="div-gauche" v-show="showTable">
        <button type="submit" class="button-annuler" onclick="history.back()">Annuler</button>
        <b-table
          striped
          hover
          :items="tab"
          responsive
          no-local-sorting
          small
          sticky-header="482px"
        ></b-table>
      </div>
      <div class="div-droite">
        <form @submit.prevent="onSubmit">
          <b-row class="ligneBouton">
            <button
              @click="verifQuery()"
              type="submit"
              id="button-appliquer"
              class="button-appliquer"
            >
              Appliquer
            </button>
            <b-modal
              v-model="modalAppliquer"
              id="modal-appliquer"
              centered
              hide-header
              hide-footer
            >
              <div
                class="cookiesContent"
                id="cookiesPopup"
                style="height: 235px !important"
              >
                <button class="close" @click="modalAppliquer = !modalAppliquer">
                  ✖
                </button>
                La requête n'est pas conforme au graphique : <br />
                <h6>{{ idModel.nomaffiche }}</h6>
                <br />
                Valeurs attendues : <br />
                <h6>{{ idModel.donnees }}</h6>
                <b-row>
                  <b-col
                    ><button
                      class="button-appliquer"
                      @click="modalAppliquer = !modalAppliquer"
                    >
                      Ok
                    </button></b-col
                  >
                </b-row>
              </div>
            </b-modal>
            <!-- <router-link :to="{ name: 'Dashboards' }"> -->
              <button
                :disabled="invalid"
                @click="doSaveGraph()"
                type="submit"
                id="button-enregistrer"
                class="button-enregistrer"
              >
                Enregistrer
              </button>
            <!-- </router-link> -->
            <button
              @click="modalSupprimer = true"
              v-b-modal.modal-center
              type="submit"
              id="button-enregistrer"
              class="button-enregistrer"
            >
              Supprimer
            </button>
            <b-modal
              v-model="modalSupprimer"
              id="modal-center"
              centered
              hide-header
              hide-footer
            >
              <div class="cookiesContent" id="cookiesPopup">
                <button class="close" @click="modalSupprimer = !modalSupprimer">
                  ✖
                </button>
                Voulez-vous vraiment supprimer ce graphique?
                <b-row>
                  <b-col
                    ><button class="button-sup-modal" @click="doDeleteGraph()">
                      Supprimer
                    </button></b-col
                  >
                  <b-col
                    ><button
                      class="button-cancel-modal"
                      @click="modalSupprimer = !modalSupprimer"
                    >
                      Annuler
                    </button></b-col
                  >
                </b-row>
              </div>
            </b-modal>
          </b-row>
          <b-row class="choixGraph">
            <div>
              <ValidationProvider rules="required" name="multiselect">
                <multiselect
                  @input="setIdModel()"
                  placeholder="Choisissez un graphique"
                  label="nomaffiche"
                  track-by="id"
                  :multiple="false"
                  v-model="idModel"
                  :options="typeGraphs"
                  :option-height="104"
                  :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="props"
                    ><img
                      class="option__image"
                      :src="require('../assets/' + props.option.img)"
                    />
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.nomaffiche
                      }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props"
                    ><img
                      class="option__image"
                      :src="require('../assets/' + props.option.img)"
                    />
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.nomaffiche
                      }}</span>
                    </div>
                  </template>
                </multiselect>
              </ValidationProvider>
            </div>
          </b-row>
          <b-row>
            <div class="choixParams">
              <div class="contenue-choixParams">
                <h4 class="paramGraphique">Paramètres du graphique</h4>
                <h5 class="titreParam">Titre</h5>
                <ValidationProvider name="Titre" rules="required">
                  <b-form-input
                    autocomplete="off"
                    placeholder="Veuillez renseigner un titre"
                    v-model="graphSelected.name"
                    id="input-titre-graph"
                    class="input-titre-graph"
                  ></b-form-input>
                </ValidationProvider>
                <h5 class="descriptionGraph">Description</h5>
                <div class="divtextArea-desc">
                  <ValidationProvider name="Description" rules="required">
                    <b-form-textarea
                      v-model="graphSelected.description"
                      id="textArea-desc"
                      class="textArea-desc"
                      size="sm"
                      placeholder="Veuillez renseigner une description"
                      type="text"
                      autocomplete="off"
                    >
                    </b-form-textarea>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </b-row>
        </form>
      </div>
      <b-row class="div-bas">
        <div class="div-requetes">
          <h4 class="textRequete">Requête</h4>
          <h6>Base :</h6>
          <div class="sourceDonnes">
            <multiselect
              @input="(graphSelected.id_database = DBSelected.id), setIdModel()"
              :show-labels="false"
              :option-height="104"
              v-model="DBSelected"
              track-by="id"
              label="nom_database"
              placeholder="Source data"
              :options="valueDB"
              :searchable="false"
              :allow-empty="false"
              style="width: 175px; float: right"
            >
              <template
                class="multiselect-label"
                slot="singleLabel"
                slot-scope="props"
                >{{ props.option.nom_database }}</template
              >
            </multiselect>
          </div>
          <codemirror
            v-model="graphSelected.sql"
            id="codeMirorSQL"
            class="codeMirorSQLModify"
            ref="editQuerySQL"
            :options="cmOptions"
          />
          <div>
            <b-button
              v-b-toggle.collapse-1
              @click="isClickedVariables = !isClickedVariables"
              variant="light"
              class="button-requete"
              >Variables<font-awesome-icon
                class="iconVariables"
                :icon="isClickedVariables ? 'angle-down' : 'angle-right'"
            /></b-button>
            <b-button
              @click="data(), (buttonClicked = !buttonClicked)"
              v-b-toggle.collapse-2
              variant="light"
              class="button-requete"
              >SQL<font-awesome-icon
                class="iconSQL"
                :icon="isClickedSQL ? 'angle-down' : 'angle-right'"
            /></b-button>
            <b-button
              v-b-toggle.collapse-3
              @click="isClickedFormatage = !isClickedFormatage"
              variant="light"
              class="button-requete"
              >Formatage données<font-awesome-icon
                class="iconVariables"
                :icon="isClickedFormatage ? 'angle-down' : 'angle-right'"
            /></b-button>
            <b-collapse id="collapse-1" class="mt-2">
              <b-card class="card-help-variables">
                <p class="categorie-variables">Macros :</p>
                <li
                  class="li-variables"
                  v-for="listeMacro in listeMacros"
                  :key="listeMacro.ligne"
                >
                  {{ listeMacro.ligne }}
                </li>
                <p></p>
                <p class="categorie-variables">Filtres :</p>
                <li
                  class="li-variables"
                  v-for="listefiltre in listeFiltres"
                  :key="listefiltre.ligne"
                >
                  {{ listefiltre.ligne }}
                </li>
              </b-card>
            </b-collapse>
            <b-collapse id="collapse-2" class="mt-2">
              <b-card class="card-help-variables">
                <b-form-textarea
                  v-model="queryDebug"
                  type="text"
                  readonly
                  id="textArea-desc"
                  class="textArea-query"
                  size="xl"
                >
                </b-form-textarea>
              </b-card>
            </b-collapse>
            <b-collapse id="collapse-3" class="mt-2">
              <b-card class="card-help-formatage">
                <tr>
                  <th>
                    <div class="graph-selected">
                      <h5 class="titreTypeSelected">
                        Type de graphique selectionné :
                      </h5>
                      <div class="element-in-container">
                        <table class="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>Nom</th>
                              <th>Paramètres attendus</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ idModel.nomaffiche }}</td>
                              <td>{{ idModel.donnees }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="all-graphs">
                      <h5 class="titreParam">Tous les graphiques :</h5>
                      <div class="element-in-container">
                        <table class="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>Nom</th>
                              <th>Paramètres attendus</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="typeGraph in typeGraphs"
                              :key="typeGraph.id"
                            >
                              <td>{{ typeGraph.nomaffiche }}</td>
                              <td>{{ typeGraph.donnees }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </th>
                </tr>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </b-row>
      <b-row class="div-padding-bas"></b-row>
    </div>
  </ValidationObserver>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { codemirror } from "vue-codemirror";
import { format } from "sql-formatter";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/idea.css";
import "codemirror/theme/panda-syntax.css";
import "codemirror/addon/hint/show-hint.css";

import "codemirror/lib/codemirror";
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";

export default {
  name: "ModifyGraph",
  components: {
    Multiselect,
    codemirror,
  },
  data: () => ({
    DBSelected: "",
    valueDB: [],
    showTable: false,
    queryFormat: "",
    modalSupprimer: false,
    modalAppliquer: false,
    buttonClicked: false,
    queryDebug: "",
    tab: [],
    idModel: { id: "", img: "", nomaffiche: "", donnees: "" },
    typeGraphs: [],
    selected: [],

    loading: false,
    isClickedVariables: false,
    isClickedFormatage: false,
    isClickedSQL: false,
    listeMacros: [
      { ligne: "$__idEnv -> id de l'environnement actuel " },
      { ligne: "$__idbdname -> Nom de la BD nécessaire  " },
      { ligne: "$__idbdcode -> Code de la BD nécessaire  " },
      { ligne: "$__default_pgsql_host  " },
      { ligne: "$__default_pgsql_user  " },
      { ligne: "$__default_pgsql_pass  " },
    ],
    listeFiltres: [
      { ligne: "$__timeFilter -> Fourchette d'années " },
      { ligne: "$__zoneFilter -> Isole une zone " },
    ],
    cmOptions: {
      indentAuto: true,
      tabSize: 4,
      lineNumbers: true,
      line: true,
      indentWithTabs: true,
      smartIndent: true,
      autofocus: false,
      mode: "text/x-mariadb",
      theme: "idea",
      showHint: true,
      extraKeys: { Ctrl: "autocomplete" },
      hintOptions: {
        completeSingle: false,
        tables: {
          // table1: ["name", "score", "birthDate"],
          // table2: ["name", "population", "size"],
        },
      },
    },
    search: {
      searchText: "",
      searchTag: [],
      id: "",
    },
  }),
  computed: {
    graphSelected: {
      get() {
        return this.graph;
      },
      set(value) {
        this.setGraph(value);
      },
    },
    ...Vuex.mapGetters("Graph", ["graph"]),
    ...Vuex.mapGetters("Dashboard", [
      "dashboard",
      "yearMin",
      "yearMax",
      "yearValue",
    ]),
  },
  watch: {
    loading: {
      handler: function (val) {
        this.$nextTick(() => {
          this.refreshGraph();
        });
      },
    },
  },
  methods: {
    setIdModel() {
      this.graphSelected.graph_type_id = this.idModel.id;
    },
    ...Vuex.mapActions("Graph", [
      "getGraph",
      "getGraphs",
      "setGraph",
      "saveGraph",
      "deleteGraph",
    ]),
    data() {
      if (this.buttonClicked == false) {
        Vue.axios
          .get(
            window.adresseAPI +
              "stats/graphs/sql?query=" +
              this.graphSelected.sql +
              "&minYear=" +
              parseInt(this.yearMin) +
              "&maxYear=" +
              parseInt(this.yearMax)
          )
          .then((response) => {
            this.queryDebug = format(response.data.data, { tabWidth: 10 });
          });
      }
    },
    doDeleteGraph() {
      this.deleteGraph(this.graphSelected);
      this.$router.push("/tableaux-de-bord");
    },
    doSaveGraph() {
      this.saveGraph(this.graphSelected);
      history.back()
    },
    returnDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    verifQuery() {
      if (this.idModel.id == 3) {
        this.modalAppliquer = false;
        this.drawChart(this.graphSelected);
      } else {
        let beforeFrom = this.graphSelected.sql.split('FROM "')[0];
        beforeFrom = beforeFrom.split(/[\s, ]+/);
        let array = this.idModel.donnees.split(/[\s, ]+/);
        let check = array.every((element) => {
          return beforeFrom.includes(element);
        });
        if (check) {
          this.modalAppliquer = false;
          this.drawChart(this.graphSelected);
        } else {
          this.modalAppliquer = true;
        }
      }
    },
    getTypeGraphs() {
      Vue.axios.get(window.adresseAPI + "stats/typeGraph").then((response) => {
        let tabType = [];
        let rep = response.data.data;
        rep.forEach((element) => {
          tabType.push({
            id: element.id,
            nomaffiche: element.nomaffiche,
            img: element.img,
            donnees: element.donnees,
          });
        });
        this.typeGraphs = tabType;
      });
    },
    getConnexions() {
      return new Promise((resolve) => {
        Vue.axios
          .get(window.adresseAPI + "stats/connexions")
          .then((response) => {
            this.valueDB = response.data.data;
            resolve();
          });
      });
    },
    drawChart(graph) {
      this.loading = graph.id;
      let data = [graph, this.yearMin, this.yearMax, this.graph.id_database];
      Vue.axios
        .post(window.adresseAPI + "stats/graph/preview", data)
        .then((response) => {
          if (this.idModel.id == 6 || this.idModel.id == 7) {
            this.showTable = true;
            this.tab = response.data.data;
          } else {
            this.showTable = false;
            let htmlElement = this.$refs[graph.id];
            let myChart = this.$echarts.init(htmlElement, null, {
              renderer: "canvas",
              width: (window.innerWidth * 54) / 100,
              height: 482,
            });
            myChart.clear();
            myChart.setOption(response.data.data);
            myChart.resize();
            this.loading = false;
          }
          window.Event.$emit('after-response');
        });
        
    },
  },

  async mounted() {
    window.Event.$emit('before-request');
    let rep1 = await this.getTypeGraphs();

    let rep2 = await this.getGraph(this.$route.params.id);
    let rep4 = await this.getConnexions();

    this.idModel = {
      id: this.graphSelected.graph_type_id,
      img: this.graphSelected.img,
      nomaffiche: this.graphSelected.nomaffiche,
      donnees: this.graphSelected.donnees,
    };

    this.DBSelected = {
      id: this.graphSelected.id_database,
      nom_database: this.graphSelected.nom_database,
    };
    let rep3 = await this.drawChart(this.graphSelected);
    
    this.value[0] = parseInt(this.yearMin);
    this.value[1] = parseInt(this.yearMax);
    
  },
};
</script>

