<template>
  <div>
    <b-overlay
      style="z-index:99999999!important;"
      variant="dark"
      no-wrap
      :show="overlay"
      opacity="0.7"
    >
      <template v-slot:overlay>
        <!-- <b-card class="text-center" style="width: 400px;"> -->
        <!-- <b-card-text class="text-center"> -->
        <div class="mb-4 text-center">
          <b-spinner
            style="width: 8rem; height: 8rem; color: #DB524A"
            label="..."
          ></b-spinner>
        </div>
        <div class="text-center">
          <span style="font-size:1.5rem; color:#fff">Chargement...</span>
        </div>
        <!-- </b-card-text> -->
        <!-- </b-card> -->
      </template>
    </b-overlay>

    <toolbar
      v-if="
        $route.name != 'Env' &&
        $route.name != 'NoLogin' &&
        $route.name != 'Login' &&
          $route.name != 'NotFound' &&
          $route.name != 'NoDosConnected' &&
          $route.name != 'ComingSoon'
      "
    ></toolbar>
    <sidebar
      v-if="
        $route.name != 'Env' &&
        $route.name != 'NoLogin' &&
          $route.name != 'NotFound' &&
          $route.name != 'Login' &&
          $route.name != 'NoDosConnected' &&
          $route.name != 'ComingSoon' && 
          $route.params.token == null
      "
    ></sidebar>
    <div id="content">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import sideBar from "./components/Sidebar.vue";
import toolBar from "./components/Toolbar.vue";

import Vuex from "vuex";
export default {
  name: "Statistiques",
  computed: {
    ...Vuex.mapGetters("Authentification", ["userConnected"]),
  },
  methods: {
    ...Vuex.mapActions("Authentification", [
      "getUserConnected", "setTokenLectureSeule",
    ]),
    showSpinner() {
      this.overlay = true;
    },
    hideSpinner() {
      this.overlay = false;
    },
  },
  components: {
    sidebar: sideBar,
    toolbar: toolBar,
  },
 async  mounted() {
    if (this.$route.params.token) {
      await this.setTokenLectureSeule(this.$route.params.token);
    } else {
      await this.getUserConnected();
    }
  },
  data: () => ({
    overlay: false,
  }),
  created() {
    window.Event.$on("before-request", this.showSpinner);
    window.Event.$on("request-error", this.hideSpinner);
    window.Event.$on("after-response", this.hideSpinner);
    window.Event.$on("response-error", this.hideSpinner);
  },
  beforeDestroy() {
    window.Event.$off("before-request", this.showSpinner);
    window.Event.$off("request-error", this.hideSpinner);
    window.Event.$off("after-response", this.hideSpinner);
    window.Event.$off("response-error", this.hideSpinner);
  },
};
</script>

<style lang="scss">
@import "styles/index.scss";
</style>
