import router from "../router";
import Vue from "vue";


const Dossier = {
    namespaced: true,
    state: {
        dossier: {}
    },
    actions: {
        getDosConnected(context) {
            var url = window.adresseAPI + "dossier" + (context.rootGetters['Authentification/tokenLectureSeule'] != null ?  '/token/' + context.rootGetters['Authentification/tokenLectureSeule'] : '');
             return new Promise((resolve) => {
                Vue.axios.get(url).then(
                    response => {
                        if (typeof response.data.data != "undefined") {
                            context.commit("GETDOSCONNECTED", response.data.data);
                            resolve();
                        } 
                        else {
                            router.push({
                                name : 'NoDosConnected'
                            })
                            resolve();
                        }
                    }
                );
            })
        }
    },
    mutations: {
        GETDOSCONNECTED: (state, dossier) => {
            state.dossier = dossier;
        }
    },
    getters: {
        isAuthenticated: state => !!state.token,
        dosConnected: state => state.dossier
    }
}
export default Dossier;