<template>
  <ValidationObserver v-slot="{ invalid }">
    <div class="content-news">
      <div class="div-gauche" v-show="!showTable">
        <router-link
          :to="{ name: 'Dashboards' }"
          class="btn-sidebar sidebar-link sidebar-bloc"
        >
          <button type="submit" class="button-annuler">Annuler</button>
        </router-link>
        <div :ref="nouveauGraph.nomGraph" class="content-bloc2"></div>
      </div>
      <div class="div-gauche" v-show="showTable">
        <router-link
          :to="{ name: 'Dashboards' }"
          class="btn-sidebar sidebar-link sidebar-bloc"
        >
          <button type="submit" class="button-annuler">Annuler</button>
        </router-link>
        <b-table
          striped
          hover
          :items="tab"
          responsive
          no-local-sorting
          small
          sticky-header="482px"
        ></b-table>
      </div>
      <div class="div-droite">
        <form @submit.prevent="onSubmit">
          <b-row class="ligneBouton">
            <button
              @click="verifQuery()"
              type="submit"
              id="button-appliquer"
              class="button-appliquer"
            >
              Appliquer
            </button>
            <b-modal
              v-model="modalAppliquer"
              id="modal-appliquer"
              centered
              hide-header
              hide-footer
            >
              <div
                class="cookiesContent"
                id="cookiesPopup"
                style="height: 235px !important"
              >
                <button class="close" @click="modalAppliquer = !modalAppliquer">
                  ✖
                </button>
                La requête n'est pas conforme au graphique : <br />
                <h6>{{ idModel.nomaffiche }}</h6>
                <br />
                Valeurs attendues : <br />
                <h6>{{ idModel.donnees }}</h6>
                <b-row>
                  <b-col
                    ><button
                      class="button-appliquer"
                      @click="modalAppliquer = !modalAppliquer"
                    >
                      Ok
                    </button></b-col
                  >
                </b-row>
              </div>
            </b-modal>
            <button
              :disabled="invalid"
              @submit="doAddGraph"
              @click="doAddGraph()"
              type="submit"
              id="button-enregistrer"
              class="button-enregistrer"
            >
              Enregistrer
            </button>
          </b-row>
          <b-row class="choixGraph">
            <div>
              <ValidationProvider rules="required" name="multiselect">
                <multiselect
                  @input="nouveauGraph.graph_type_id = idModel.id"
                  placeholder="Choisissez un graphique"
                  label="nomaffiche"
                  track-by="id"
                  :multiple="false"
                  v-model="idModel"
                  :options="typeGraphs"
                  :option-height="104"
                  :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="props"
                    ><img
                      class="option__image"
                      :src="require('../assets/' + props.option.img)"
                    />
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.nomaffiche
                      }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props"
                    ><img
                      class="option__image"
                      :src="require('../assets/' + props.option.img)"
                    />
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.nomaffiche
                      }}</span>
                    </div>
                  </template>
                </multiselect>
              </ValidationProvider>
            </div>
          </b-row>
          <b-row>
            <div class="choixParams">
              <div class="contenue-choixParams">
                <h4 class="paramGraphique">Paramètres du graphique</h4>
                <h5 class="titreParam">Titre</h5>
                <ValidationProvider name="Titre" rules="required">
                  <b-form-input
                    placeholder="Veuillez renseigner un titre"
                    autocomplete="off"
                    v-model="nouveauGraph.nomGraph"
                    id="input-titre-graph"
                    class="input-titre-graph"
                  ></b-form-input>
                </ValidationProvider>
                <h5 class="descriptionGraph">Description</h5>
                <div class="divtextArea-desc">
                  <ValidationProvider name="Description" rules="required">
                    <b-form-textarea
                      autocomplete="off"
                      v-model="nouveauGraph.description"
                      id="textArea-desc"
                      class="textArea-desc"
                      size="sm"
                      placeholder="Veuillez renseigner une description"
                      type="text"
                    >
                    </b-form-textarea>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </b-row>
        </form>
      </div>
      <b-row class="div-bas">
        <div class="div-requetes">
          <h4 class="textRequete">Requête</h4>
          <h6>Base :</h6>
          <div class="sourceDonnes">
            <multiselect
              @input="nouveauGraph.bd = DBSelected.id"
              :show-labels="false"
              :option-height="104"
              v-model="DBSelected"
              track-by="id"
              label="nom_database"
              placeholder="Source data"
              :options="valueDB"
              :searchable="false"
              :allow-empty="false"
              style="width: 175px; float: right"
            >
              <template
                class="multiselect-label"
                slot="singleLabel"
                slot-scope="props"
                >{{ props.option.nom_database }}</template
              >
            </multiselect>
          </div>
          <pre class="language-json"><code>{{ value  }}</code></pre>
          <codemirror
            v-model="nouveauGraph.sql"
            id="codeMirorSQL"
            class="codeMirorSQL"
            ref="editQuerySQL"
            :options="cmOptions"
          />
          <div class="bouton-requete">
            <b-button
              v-b-toggle.collapse-1
              @click="isClickedVariables = !isClickedVariables"
              variant="light"
              class="button-requete"
              >Variables<font-awesome-icon
                class="iconVariables"
                :icon="isClickedVariables ? 'angle-down' : 'angle-right'"
            /></b-button>
            <b-button
              @click="data(), (buttonClicked = !buttonClicked)"
              v-b-toggle.collapse-2
              variant="light"
              class="button-requete"
              >SQL<font-awesome-icon
                class="iconSQL"
                :icon="isClickedSQL ? 'angle-down' : 'angle-right'"
            /></b-button>
            <b-button
              v-b-toggle.collapse-3
              @click="isClickedFormatage = !isClickedFormatage"
              variant="light"
              class="button-requete"
              >Formatage données<font-awesome-icon
                class="iconVariables"
                :icon="isClickedFormatage ? 'angle-down' : 'angle-right'"
            /></b-button>
            <b-collapse id="collapse-1" class="mt-2">
              <b-card class="card-help-variables">
                <p class="categorie-variables">Macros :</p>
                <li
                  class="li-variables"
                  v-for="listeMacro in listeMacros"
                  :key="listeMacro.ligne"
                >
                  {{ listeMacro.ligne }}
                </li>
                <p></p>
                <p class="categorie-variables">Filtres :</p>
                <li
                  class="li-variables"
                  v-for="listefiltre in listeFiltres"
                  :key="listefiltre.ligne"
                >
                  {{ listefiltre.ligne }}
                </li>
              </b-card>
            </b-collapse>
            <b-collapse id="collapse-2" class="mt-2">
              <b-card class="card-help-variables">
                <b-form-textarea
                  v-model="queryDebug"
                  type="text"
                  readonly
                  id="textArea-desc"
                  class="textArea-query"
                  size="xl"
                >
                </b-form-textarea>
              </b-card>
            </b-collapse>
            <b-collapse id="collapse-3" class="mt-2">
              <b-card class="card-help-formatage">
                <div class="graph-selected" v-show="graphSelected">
                  <h5 class="titreTypeSelected">
                    Type de graphique selectionné :
                  </h5>
                  <div class="grid-container">
                    <div class="element-in-container">
                      <table class="tableDonnes">
                        <caption
                          style="
                            caption-side: top;
                            text-align: center;
                            background: #ffa1a1;
                          "
                        >
                          {{
                            idModel.nomaffiche
                          }}
                        </caption>
                        <tr>
                          <th>{{ idModel.donnees }}</th>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <h5 class="titreParam">Tous les graphiques :</h5>
                <div class="grid-container">
                  <div
                    class="element-in-container"
                    v-for="typeGraph in typeGraphs"
                    :key="typeGraph.id"
                  >
                    <table class="tableDonnes">
                      <caption
                        style="
                          caption-side: top;
                          text-align: center;
                          background: #ffa1a1;
                        "
                      >
                        {{
                          typeGraph.nomaffiche
                        }}
                      </caption>
                      <tr>
                        <th>{{ typeGraph.donnees }}</th>
                      </tr>
                    </table>
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </b-row>
      <b-row class="div-padding-bas"></b-row>
    </div>
  </ValidationObserver>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { codemirror } from "vue-codemirror";
import { format } from "sql-formatter";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/idea.css";
import "codemirror/theme/panda-syntax.css";
import "codemirror/addon/hint/show-hint.css";

import "codemirror/lib/codemirror";
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";

export default {
  name: "AddGraph",
  components: {
    Multiselect,
    codemirror,
  },
  data: () => ({
    DBSelected: "",
    valueDB: [],
    options: [
      { name: "Clicmap" },
      { name: "MySql" },
      { name: "Applicative" },
      { name: "Cartographique" },
      { name: "Mutualisée" },
    ],
    modalAppliquer: false,
    showTable: false,
    nouveauGraph: {
      app: "",
      graph_type_id: "",
      nomGraph: "",
      description: "",
      bd: "",
      sql: "",
    },
    buttonClicked: false,
    queryDebug: "",
    tab: [],
    sqlContent: {
      query: "",
      minyear: "",
      maxyear: "",
    },
    idModel: "",
    typeGraphs: [],
    selected: [],

    loading: false,
    graphSelected: false,
    isClickedVariables: false,
    isClickedFormatage: false,
    isClickedSQL: false,
    listeMacros: [
      { ligne: "$__idEnv -> id de l'environnement actuel " },
      { ligne: "$__idbdname -> Nom de la BD nécessaire  " },
      { ligne: "$__idbdcode -> Code de la BD nécessaire  " },
      { ligne: "$__default_pgsql_host  " },
      { ligne: "$__default_pgsql_user  " },
      { ligne: "$__default_pgsql_pass  " },
    ],
    listeFiltres: [
      { ligne: "$__timeFilter -> Fourchette d'années " },
      { ligne: "$__zoneFilter -> Isole une zone " },
    ],
    cmOptions: {
      indentAuto: true,
      tabSize: 4,
      lineNumbers: true,
      line: true,
      indentWithTabs: true,
      smartIndent: true,
      autofocus: false,
      mode: "text/x-mariadb",
      theme: "idea",
      showHint: true,
      extraKeys: { Ctrl: "autocomplete" },
      hintOptions: {
        completeSingle: false,
        tables: {
          // table1: ["name", "score", "birthDate"],
          // table2: ["name", "population", "size"],
        },
      },
    },
    search: {
      searchText: "",
      searchTag: [],
      id: "",
    },
  }),
  computed: {
    ...Vuex.mapGetters("Dashboard", [
      "valueDB",
      "dashboard",
      "yearMin",
      "yearMax",
      "yearValue",
    ]),
  },
  watch: {
    loading: {
      handler: function (val) {
        this.$nextTick(() => {
          this.refreshGraph();
        });
      },
    },
  },
  methods: {
    doAddGraph() {
      this.nouveauGraph.app = this.dashboard.app;
      this.addGraph(this.nouveauGraph);
    },
    refreshGraph() {
      this.graph.clear();
      this.graph.resize();
    },
    // ...Vuex.mapActions("Dashboard", "getConnexions"),
    ...Vuex.mapActions("Graph", [
      "getGraphs",
      "setGraph",
      "saveGraph",
      "addGraph",
    ]),
    data() {
      if (this.buttonClicked == false) {
        Vue.axios
          .get(
            window.adresseAPI +
              "stats/graphs/sql?query=" +
              this.nouveauGraph.sql +
              "&minYear=" +
              parseInt(this.yearMin) +
              "&maxYear=" +
              parseInt(this.yearMax) +
              "&DB=" + 
              this.DBSelected
          )
          .then((response) => {
            this.queryDebug = format(response.data.data, { tabWidth: 10 });
          });
      }
    },
    verifQuery() {
      if (this.idModel.id == 3) {
        this.modalAppliquer = false;
        this.drawChart(this.nouveauGraph);
      } else {
        let beforeFrom = this.nouveauGraph.sql.split('FROM "')[0];
        let array = this.idModel.donnees.split(/[\s, ]+/);
        beforeFrom = beforeFrom.split(/[\s, ]+/);
        let check = array.every((element) => {
          return beforeFrom.includes(element);
        });
        if (check) {
          this.modalAppliquer = false;
          this.drawChart(this.nouveauGraph);
        } else {
          this.modalAppliquer = true;
        }
      }
    },
    getTypeGraphs() {
      Vue.axios.get(window.adresseAPI + "stats/typeGraph").then((response) => {
        let tabType = [];
        let rep = response.data.data;
        rep.forEach((element) => {
          tabType.push({
            id: element.id,
            nomaffiche: element.nomaffiche,
            img: element.img,
            donnees: element.donnees,
            data: element.data,
          });
        });
        this.typeGraphs = tabType;
      });
    },
    getConnexions() {
      return new Promise((resolve) => {
        Vue.axios
          .get(window.adresseAPI + "stats/connexions")
          .then((response) => {
            this.valueDB = response.data.data;
            resolve();
          });
      });
    },
    drawChart(graph) {
      this.graphSelected = true;
      this.loading = graph.nomGraph;
      graph.app = this.dashboard.app;
      let data = [graph, this.yearMin, this.yearMax, this.DBSelected.id];
      Vue.axios
        .post(window.adresseAPI + "stats/graph/preview", data)
        .then((response) => {
          if (
            this.nouveauGraph.graph_type_id == "6" ||
            this.nouveauGraph.graph_type_id == "7"
          ) {
            this.showTable = true;
            this.tab = response.data.data;
          } else {
            this.showTable = false;
            let htmlElement = this.$refs[graph.nomGraph];
            let myChart = this.$echarts.init(htmlElement, null, {
              renderer: "canvas",
              width: (window.innerWidth * 54) / 100,
              height: 482,
            });
            myChart.clear();
            myChart.setOption(response.data.data);
            this.loading = false;
          }
        });
    },
  },

  async mounted() {
    window.Event.$emit('before-request');
    let rep1 = await this.getTypeGraphs();
    let rep2 = await this.getConnexions();
    window.Event.$emit('after-response');
    this.value[0] = parseInt(this.yearMin);
    this.value[1] = parseInt(this.yearMax);
   
  },
};
</script>
<style>
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 10px;
}
.tableDonnes {
  margin-bottom: 15px;
  margin-right: 20px;
  width: 300px;
  border: 1px solide #c0c0c0;
  border-collapse: effondrer;
  padding: 5px;
  text-align: center;
}
.tableDonnes th {
  border: 1px solide #ffa1a1;
  padding: 5px;
  background: #f0f0f0;
  text-align: center;
}
.tableDonnes td {
  border: 1px solide #c0c0c0;
  padding: 5px;
  background: #ff6b6b;
  text-align: center;
}
</style>
