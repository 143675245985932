import router from "../router";
import Vue from "vue";


const Graphs = {
    namespaced: true,
    state: {
        sqlContent: {
            query: null,
            minYear: null,
            maxYear: null,
        },
        graphs: {
            id: null,
            graph_type_id: null,
            name: null,
            app: null,
            data: null,
            description: null,
            date_ajout: null,
            sql: null,
            bd: null,
            nomaffiche: null,
        },
        idModele: {},
        graph: {},
        tags: [],
        types: [],
    },
    actions: {
        setIdModel(context, idModele) {
            context.commit("SETIDMODEL", idModele)
        },
        setGraph(context, dashboard) {
            context.commit("SETGRAPH", dashboard);
        },
        getGraph(context, id) {
            return new Promise((resolve) => {
                Vue.axios.get(window.adresseAPI + "stats/graphs/" + id).then(
                    response => {
                        context.commit("SETGRAPH", response.data.data);
                        resolve();
                    }
                );
            })
        },
        getGraphs(context, searchValue) {
            return new Promise((resolve) => {
                let searchText = '?searchText=' + searchValue.searchText;
                let searchTag = '&searchTag=' + JSON.stringify(searchValue.searchTag);
                Vue.axios.get(window.adresseAPI + "stats/graphs" + searchText + searchTag).then(
                    response => {
                        context.commit("GETGRAPHS", response.data.data);
                        resolve();
                    }
                );
            })
        },
        getTagsGraphs(context) {
            return new Promise((resolve) => {
                Vue.axios.get(window.adresseAPI + "stats/tags").then(
                    response => {
                        context.commit("GETTAGSGRAPHS", response.data.data);
                        resolve();
                    }
                );
            })
        },
        addGraph(context, graph) {
            return new Promise((resolve) => {
                Vue.axios.post(window.adresseAPI + "stats/graphs", graph).then(
                    response => {
                        if (response.data.success == true) {
                            Vue.prototype.$awn.success(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        } else {
                            Vue.prototype.$awn.alert(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        }
                        resolve();
                    }
                )
            })
        },
        saveGraph(context, graph) {
            return new Promise((resolve) => {
                Vue.axios.put(window.adresseAPI + "stats/graphs/" + graph.id, graph).then(
                    response => {
                        if (response.data.success == true) {
                            Vue.prototype.$awn.success(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        } else {
                            Vue.prototype.$awn.alert(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 30000
                                }
                            );
                        }
                        // context.commit("SAVEGRAPHSDASHBOARDS", response.data.data);
                        resolve();
                    }
                );
            })
        },
        deleteGraph(context, graph) {
            return new Promise((resolve) => {
                Vue.axios.delete(window.adresseAPI + "stats/graph/" + graph.id).then(
                    response => {
                        if (response.data.success == true) {
                            Vue.prototype.$awn.success(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 3000
                                }
                            );
                            context.commit("DELETEGRAPH", graph);
                        } else {
                            Vue.prototype.$awn.alert(
                                response.data.msg.toString(),
                                {
                                    position: "bottom-right",
                                    timeout: 3000
                                }
                            );
                        }
                        resolve();
                    }
                );
            })
        },
    },

    mutations: {
        DELETEGRAPH: (state, graph) => {
            let index = state.graph.findIndex(element => element.id == graph.id);
            state.graph.splice(index, 1);
        },
        SETIDMODEL: (state, idModele) => {
            state.idModele = idModele
        },
        SETGRAPH: (state, graph) => {
            state.graph = graph;
        },
        GETGRAPHS: (state, graphs) => {
            state.graphs = graphs;
        },
        GETTAGSGRAPHS: (state, tags) => {
            state.tags = tags;
        },
        GETTYPEGRAPHS: (state, types) => {
            state.types = types;
        },
        ADDGRAPHBD: (state, graph) => {
            state.graph.push(graph);
        },
        GETSQLCONTENT: (state, sqlContent) => {
            state.sqlContent = sqlContent;
        }
    },
    getters: {
        graph: state => state.graph,
        sqlContent: state => state.sqlContent,
        graphs: state => state.graphs,
        tags: state => state.tags,
        types: state => state.types,
        idModele: state => state.idModele,
    }
}
export default Graphs;