<template>
  <b-row class="content-news">
    <div class="header-right">
      <b-modal
        style = "padding: 35px !important;"
        v-model="modalGraphNews"
        title="Graphiques"
        hide-header
        hide-footer
        size="lg"
        class="ajout-graph"
      >
        <div>
          <div class="container-remove-graph-news">
            <a class="icon-remove-graph">
              <font-awesome-icon
                class="remove-icon"
                @click="closeModal()"
                icon="times"
            /></a>
          </div>
          <multiselect
            v-model="searchValue"
            placeholder="Sélectionner un tableau de bord"
            label="name"
            class="multiSelectNews"
            track-by="name"
            :options="dashboards"
            :multiple="false"
            :taggable="false"
            @input="fireSlider"
            selectLabel=""
            deselectLabel=""
          >
          </multiselect>

          <div class="mt-3-news">
            Tableau de bord sélectionné :
            <strong>{{ searchValue.name }}</strong>
          </div>

          <b-button
            @click="toDashboard(searchValue.id)"
            type="submit"
            class="valider-news"
            variant="danger"
            >Valider</b-button
          >
        </div>
      </b-modal>
    </div>
    <b-col v-for="value in filteredList" :key="value.id">
      
      <div class="container-card-news">

        <b-card
          :title="value.name"
          :img-src="require('../assets/' + value.img)"
          img-left
          :sub-title="value.description"
          class="mb-2"
          style="text-align: left; "
        >
          <!-- <b-button class="btn-add-graph-news" @click="modalGraphNews = true">
            <font-awesome-icon icon="plus" />
            <span> Ajouter ce graphique</span>
          </b-button> -->

          <div class="news-date">
            <font-awesome-icon icon="calendar-alt" />
            {{ value.date_ajout }}
          </div>
        </b-card>

        <!-- <font-awesome-icon
                        :icon="value.icon"
                        class="icon-ajout-graph"
                        
                        ></font-awesome-icon> -->
      </div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
import Vuex from "vuex";
import Vue from "vue";
import router from "../router/index";

export default {
  name: "Dashboard",
  data: () => ({
    search: "",
    selected: null,
    label: null,
    charts: [],
    tab: {},
    modalGraphNews: false,
    loading: false,
    responsive: "",
    value: [],
    zone: ["test1", "test2"],
    enableCross: true,
    startAnimation: true,
    searchValue: [],
  }),
  watch: {
    loading: {
      handler: function (val) {
        this.$nextTick(() => {
          this.refreshGraphs();
        });
      },
    },
    addedGraph: {
      handler: function (val) {
        this.$nextTick(async () => {
          if (val !== false) {
            this.drawCharts(val);
            this.setAddedGraph(false);
          }
        });
      },
    },
  },

  async mounted() {
    //TODO: set dashboard with id
    // if (this.dashboards.length == 0) {
    //   await this.getDashboards();
    // }
    await this.getAnneeMinMax();
    await this.getMultiSelectValue();
    // await this.getDosConnected();

    this.setDashboardById(this.$route.params.id);

    this.value[0] = parseInt(this.yearMin);
    this.value[1] = parseInt(this.yearMax);
    if (parseInt(this.yearMin) < 1950) {
      this.value[0] = 1950;
    }

    this.drawCharts();
    window.Event.$emit('after-response');
  },
  computed: {
    dashboardValue: {
      get() {
        return this.dashboards;
      },
      set(value) {
        this.setZoneValue(value);
      },
    },
    ...Vuex.mapGetters("Dossier", ["dosConnected"]),
    ...Vuex.mapGetters("Dashboard", [
      "addboedGraph",
      "dashard",
      "dashboards",
      "countDashboard",
      "yearMax",
      "yearMin",
      "multiSelectValue",
      "showFilter",
    ]),
    ...Vuex.mapGetters("Graph", ["graphs"]),
    filteredList() {
      return this.graphs.filter((value) => {
        return (
          value.name.toLowerCase().includes(this.search.toLowerCase()) +
          value.description.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    ...Vuex.mapActions("Dossier", ["getDosConnected"]),
    ...Vuex.mapActions("Dashboard", [
      "setAddedGraph",
      "removeGraph",
      "addGraph",
      "setDashboardById",
      "getDashboards",
      "setDashboard",
      "getAnneeMinMax",
      "getMultiSelectValue",
      "setShowFilter",
    ]),

    addItem() {
      // let self = this;
      // console.log('aaa');
      let item = {
         x: 0,
        y: 0,
        w: 6,
        h: 3,
        i: null,
        isGraph: false,
        numSep: 1,
        img: null,
        icon: null,
        name: 'michelllllll',
        description: null,
        id_database: null,
      };
      this.addGraph(item);
    },
    toDashboard(id) {
      router.push({
        name: "Dashboard",
        params: { id: id },
      });
    },
    refreshGraphs() {
      this.charts.forEach((element) => {
        element.resize();
      });
    },
    closeModal() {
      this.modalGraphNews = false;
    },
    getDataGraph(element) {
      let zones = [];
      this.searchValue.forEach((element) => {
        zones.push(element.name);
        zones.push(element.description);
      });

      let params = {
        min: this.value[0],
        max: this.value[1],
        zones: zones,
      };

     
    },

    async drawCharts(id = null) {
      await this.getDataGraph();
    },
  },
};
</script>

