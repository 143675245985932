<template>
    <div>
        <b-card title="Connexion Expiré" tag="article"
            style="max-width: 20rem;     height: 100% !important;text-align: center; position: relative; margin-top: 10%; margin-left: 30%;"
            class="mb-2">
            <b-card-text >
            <b-row align-v="center" align-h="center">
                <b-col lg="10" cols="12">
                    <div class="input-group m-4">
                       
                        <b-form-input v-on:keyup.enter="doLogin" v-model="email"
                            placeholder="Identifiant"></b-form-input>
                    </div>
                    <div class="input-group m-4">
                        
                        <b-form-input v-on:keyup.enter="doLogin" v-model="password" :type="'password'"
                            placeholder="Mot de passe"></b-form-input>
                    </div>
                </b-col>
                <div class="col-xs-12 no-padding marginTop">
                    <b-button type="button" class="btn btn-primary btn-block" variant="primary" @click="doLogin()">
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                        Je m'identifie
                    </b-button>
                </div>
            </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
export default {
    name: "Login",
    data: () => ({
        email: '',
        password: '',
    }),
    methods: {
        ...Vuex.mapActions('Authentification', ['login','getUserConnected']),
        doLogin: async function () {
            var user = {
                username: this.email,
                password: this.password,
                // remember_me : false,
            };
            let token = await this.login(user);
            if (token != false) {
                console.log(token);
                let test = await this.getUserConnected();
              
                if(localStorage.getItem('back_url') && localStorage.getItem('back_url') != null){
                    let url = localStorage.getItem('back_url');
                    localStorage.removeItem('back_url');
                    router.push({ path: url});
                }else{
                    router.push({ name: "Dashboards" });
                }
               
            }
        },
    },

};
</script>
