"use strict";

import Vue from 'vue';
import axios from "axios";
import store from "../store";
import router from "../router";

// import { nextTick } from 'vue/types/umd';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const token = window.$cookies.get("token");
if (token) {
	axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}


let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    // window.Event.$emit('before-request');
    return config;
  },
  function(error) {
    // Do something with request error
    // window.Event.$emit('request-error');
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    console.log(response.data.erreur)
    // Do something with response data
    // window.Event.$emit('after-response');
     if (response.data.erreur == "Token expiré") {
      router.push({
        name : 'Login'
      })
    }
    return response;
  },
  function(error) {
    // Do something with response error
    // window.Event.$emit('response-error');
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
