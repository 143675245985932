var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{staticStyle:{"z-index":"99999999!important"},attrs:{"variant":"dark","no-wrap":"","show":_vm.overlay,"opacity":"0.7"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"mb-4 text-center"},[_c('b-spinner',{staticStyle:{"width":"8rem","height":"8rem","color":"#DB524A"},attrs:{"label":"..."}})],1),_c('div',{staticClass:"text-center"},[_c('span',{staticStyle:{"font-size":"1.5rem","color":"#fff"}},[_vm._v("Chargement...")])])]},proxy:true}])}),(
      _vm.$route.name != 'Env' &&
      _vm.$route.name != 'NoLogin' &&
      _vm.$route.name != 'Login' &&
        _vm.$route.name != 'NotFound' &&
        _vm.$route.name != 'NoDosConnected' &&
        _vm.$route.name != 'ComingSoon'
    )?_c('toolbar'):_vm._e(),(
      _vm.$route.name != 'Env' &&
      _vm.$route.name != 'NoLogin' &&
        _vm.$route.name != 'NotFound' &&
        _vm.$route.name != 'Login' &&
        _vm.$route.name != 'NoDosConnected' &&
        _vm.$route.name != 'ComingSoon' && 
        _vm.$route.params.token == null
    )?_c('sidebar'):_vm._e(),_c('div',{attrs:{"id":"content"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }